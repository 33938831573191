import { useEffect, useState, useRef } from "react";

export default function Tooltip({ children, tooltip, nowrap, text }) {
  const [isVisible, setIsVisible] = useState(false);
  const childRef = useRef();
  const [offsetWidth, setOffsetWidth] = useState(0);

  useEffect(() => {
    setOffsetWidth(childRef.current.offsetWidth);
  }, [childRef]);

  return (
    <>
      <span
        onMouseEnter={() => setIsVisible(true)}
        onMouseLeave={() => setIsVisible(false)}
        className="text-sky-700 hover:text-sky-500"
        ref={childRef}
      >
        {children}
      </span>
      <span
        className={`absolute ${!isVisible && "hidden"}`}
        style={
          text && {
            transform: `translateX(-${parseInt(offsetWidth / 2)}px)`,
          }
        }
      >
        <span>
          <span className="arrow-up absolute translate-y-[22px] -translate-x-1/2"></span>
          <span
            className={`absolute ring-2 ${
              nowrap && "whitespace-nowrap"
            } ring-zinc-200 bg-white rounded-md p-2 text-md max-w-96 -translate-x-1/2 translate-y-8 text-black drop-shadow-xl font-semibold`}
          >
            {tooltip}
          </span>
        </span>
      </span>
    </>
  );
}
