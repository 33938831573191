import React from "react";
import ItemPage from "../Components/ItemPage";

import {
    ServerIcon,
    AdjustmentsIcon,
    PlayIcon,
} from "@heroicons/react/outline";

export default function PegelPage() {
    return (
        <ItemPage
            title="Pegel Projekt"
            propLinks={[
                {
                    name: "Datenbank & API",
                    href: "/pegel",
                    hash: "#1",
                    icon: ServerIcon,
                },
                {
                    name: "Web Dashboard",
                    href: "/pegel",
                    hash: "#2",
                    icon: AdjustmentsIcon,
                },
                {
                    name: "Automatisation",
                    href: "/pegel",
                    hash: "#3",
                    icon: PlayIcon,
                },
            ]}
        >
            {/* Nav */}
            <div className="flex items-center justify-center w-full mt-8">
                <div className="absolute border-b-[1px] border-gray-400 w-2/5 z-0 hidden md:flex" />
                <div className="flex flex-col md:flex-row w-11/12 md:w-3/5 justify-between items-center font-semibold z-10">
                    <div>
                        <a
                            href="#1"
                            className="md:bg-white md:pr-2 hover:underline"
                        >
                            1. Datenbank & API
                        </a>
                    </div>
                    <div>
                        <a
                            href="#2"
                            className="md:bg-white md:px-2 hover:underline"
                        >
                            2. Web Dashboard
                        </a>
                    </div>
                    <div>
                        <a
                            href="#3"
                            className="md:bg-white md:pl-2 hover:underline"
                        >
                            3. Automatisation
                        </a>
                    </div>
                </div>
            </div>
            {/* 1. Datenbank & API */}
            <Segment
                title="1. Datenbank & API"
                id="1"
                Icon={ServerIcon}
                techstack={[
                    "MySQL",
                    "NodeJS",
                    "JavaScript",
                    "ExpressJS",
                    "Postman",
                ]}
                text="Die Datenbank wurde aus einem Datenbankschema
                            erstellt und beinhaltet User Daten, ein
                            Rollen/Rechte System, historische Daten und
                            dynamische Variablen welche entweder eine SQL für
                            eine andere Datenbank sein kann oder ein Filter um
                            Dateien im Dateisystem zu zählen. Durch das rechte
                            System können bestimmte Rollen auf bestimmte
                            Variablen in einem gegebenen Projekt zugreifen. Ein
                            User kann pro Projekt eine Rolle haben, welche dann
                            seinen Zugriff auf die Variablen definiert. Das
                            heißt, ein User kann in verschiedenen Projekten
                            verschiedene Zugriffsrechte haben. Die API dient als
                            Schnittstelle zwischen Client (in unserem Fall z.B.
                            das Web Dashboard) und der Datenbank. Sie überprüft,
                            ob der User authentifiziert ist und die Rechte für
                            seine Anfrage hat. Über die API kann man dann z.B.
                            die Variablen abfragen, sich die historischen Werte
                            geben lassen, Server Starten oder Stoppen und vieles
                            mehr."
            />
            {/* 2. Web Dashboard */}
            <Segment
                title="2. Web Dashboard"
                id="2"
                Icon={AdjustmentsIcon}
                techstack={[
                    "React",
                    "MUI",
                    "JavaScript",
                    "Axios",
                    "Minimal UI",
                ]}
                text="Das Dashboard ist das Portal, mit welchem die User
                ihre Variablen im Blick behalten können, sich den
                historischen Verlauf der Variablen ansehen können
                und die Server Monitoren können. Auf der Hauptseite
                werden alle für den Benutzer zugreifbaren Variablen
                in Kacheln angezeigt, man kann sich auch
                verschiedene Dashboards zusammen bauen, wo nur
                ausgewählte Variablen angezeigt werden. Darunter auf
                der Hauptseite befinden sich die historischen Daten
                in eine Linie Diagramm, in welchem man den Verlauf
                der Variablen begutachten kann. Auf der Serverseite
                kann man sich die für den ausgewählten laufenden
                Server anschauen und sieht Daten wie CPU-Cores, RAM
                und die Auslastung des Servers. Zusätzlich kann man
                sich die von dem Server geschriebenen Logs ansehen.
                Das Servererstellen ist dort auch möglich. Das Web
                Dashboard ist dynamisch gebaut und kann verschiedene
                Projekte und dynamische Variablen anzeigen."
            />
            {/* 2. Web Dashboard */}
            <Segment
                title="3. Automatisation"
                id="3"
                Icon={PlayIcon}
                techstack={["JavaScript", "NodeJS"]}
                text="Das gesamte Projekt ist dazu da, um eine Prozesskette zu überwachen und zu kontrollieren. Daten laufen Prozess für Prozess durch die Prozesskette, wobei die verschiedenen Prozesse verschieden lange pro Datensatz brauchen und meist auch nur eine Maximalanzahl an Datensätzen auf einmal verarbeiten können. Noch zu beachten ist, dass manche Prozesse per Hand ausgeführt werden und
                            demnach nur zu bestimmten Uhrzeiten an bestimmten Tagen bearbeitet werden. Das Target war eine Automatisation zu entwickeln, die so effektiv wie möglich große Mengen an Datensätzen durch die Prozesskette bringt und das ganze so kostengünstig
                            wie möglich (wenig Serverlaufzeiten). Wichtig war auch, dass das System nicht überlastet wird, durch zu viele gleichzeitig laufende Server. Die Automatisation erfüllt alle Anforderungen und bringt
                            die Datensätze in einem effizienten Fluss durch die
                            Prozesskette und kann nun ganz von alleine den Fluss
                            der Daten steuern. Es wird darauf geachtet das z.B.
                            bei den Prozessen die per Hand bearbeitet werden,
                            falls möglich immer Datensätze anliegen, sodass die
                            begrenzte Arbeitszeit immer genutzt werden kann. Die
                            Automatisation ist an die API angeschlossen und
                            steuert die Server darüber."
            />
        </ItemPage>
    );
}

function TechStack(props) {
    const { tech } = props;
    return (
        <div className="flex flex-col justify-between min-w-max border-b-2 md:border-b-0 md:border-r-2 border-gray-100 pb-2 md:pb-0 md:pr-2">
            <span className="font-semibold">Tech Stack:</span>
            {tech?.map((t, i) => (
                <p key={i}>{`${i + 1}. ${t}`}</p>
            ))}
        </div>
    );
}

function Segment(props) {
    const { children, title, id, Icon, techstack, text } = props;
    return (
        <div
            id={id}
            className="flex flex-col gap-2 items-center justify-center rounded-2xl drop-shadow-none bg-white p-4 md:p-8 border-2 border-gray-100 w-full  scroll-m-24"
        >
            <div className="absolute -top-4 left-6 text-xl font-bold drop-shadow-md flex flex-row items-center gap-4">
                <div className="w-5 md:w-6">
                    <Icon className="absolute w-8 md:w-9 -translate-y-1/2" />
                </div>
                <span>{title}</span>
            </div>
            {/* {children} */}
            <div>
                <div className="flex flex-col md:flex-row justify-center w-full gap-4">
                    <TechStack tech={techstack} />
                    <span className="text-justify">{text}</span>
                </div>
            </div>
        </div>
    );
}
