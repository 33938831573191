import React from "react";
import { Link } from "react-router-dom";
import NavBar from "../Components/NavBar";

const NotFound = () => (
    <div>
        <NavBar />
        <div className="flex items-center flex-col space-y-24 md:space-y-48 mt-20">
            <h1>404 - Not Found!</h1>
            <Link to="/">Go Home</Link>
        </div>
    </div>
);

export default NotFound;
