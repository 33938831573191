function Button({ children, onClick, className = "" }) {
    return (
        <div
            className={`${className} hover:from-red-600 cursor-pointer rounded-full bg-gradient-to-br from-red-500 to-orange-400 px-4 py-2 font-semibold text-white drop-shadow-md duration-200 hover:-translate-y-0.5 hover:to-orange-500`}
            onClick={onClick}
        >
            {children}
        </div>
    );
}

export default Button;
