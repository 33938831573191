import React, { useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import { Popover, Transition } from "@headlessui/react";
import {
  BookmarkAltIcon,
  CalendarIcon,
  ChartBarIcon,
  CursorClickIcon,
  MenuIcon,
  PhoneIcon,
  PlayIcon,
  RefreshIcon,
  ShieldCheckIcon,
  SupportIcon,
  ViewGridIcon,
  XIcon,
  UserIcon,
  MailIcon,
} from "@heroicons/react/outline";

export default function NavBar(props) {
  const { propLinks, translatey } = props;
  const [showScroll, setShowScroll] = useState(false);
  const links = propLinks
    ? propLinks
    : [
        {
          name: "Technologien",
          href: "/",
          hash: "#tech",
          icon: CursorClickIcon,
        },
        {
          name: "Über mich",
          href: "/",
          hash: "#about",
          icon: UserIcon,
        },
        {
          name: "Projekte",
          href: "/",
          hash: "#projects",
          icon: ViewGridIcon,
        },
      ];

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 10) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 10) {
      setShowScroll(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", checkScrollTop);
    return () => {
      window.removeEventListener("scroll", checkScrollTop);
    };
  }, [showScroll]);

  return (
    <Popover className="relative bg-white">
      <div
        className={`flex justify-between px-12 md:px-0 md:justify-around items-center fixed ${
          translatey && "md:-translate-y-full"
        } duration-300 ease-in-out top-0 w-full border-b-[1px] border-gray-200 ${
          showScroll &&
          "backdrop-blur-md bg-white bg-opacity-200 shadow-md md:translate-y-0"
        } z-10`}
      >
        <div className="flex items-center my-2">
          {/* Logo */}
          <div className="md:mr-3">
            <a
              href="/#"
              className="flex items-center md:hover:scale-110 duration-150"
            >
              <img
                src="https://avatars.githubusercontent.com/u/51781549?v=4"
                className="w-12 hover:brightness-105 shadow-md rounded-full"
              />
            </a>
          </div>
          {/* Main Nav */}
          <Popover.Group as="nav" className="hidden md:flex space-x-10">
            <div className="flex items-center space-x-4 font-semibold">
              {links.map((item) => (
                <a
                  key={item.name}
                  href={`${item.href}${item.hash}`}
                  className="text-gray-800 hover:text-black"
                >
                  {item.name}
                </a>
              ))}
            </div>
          </Popover.Group>
        </div>
        <Popover.Group as="nav" className="flex md:hidden space-x-10">
          <div className="flex items-center space-x-4 font-semibold">
            <p>Pau Kraft</p>
          </div>
        </Popover.Group>
        {/* Right Nav */}
        <div>
          <div className="-mr-2 -my-2 md:hidden">
            <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none">
              <span className="sr-only">Open menu</span>
              <MenuIcon className="h-6 w-6" aria-hidden="true" />
            </Popover.Button>
          </div>
          <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
            <a
              href="mailto:anfrage@omegalul.co"
              className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-2 py-2 border border-transparent rounded-full shadow-md hover:shadow-lg hover:border-black bg-yellow-400 text-base font-medium text-black"
            >
              <MailIcon className="h-6 w-6 text-white" aria-hidden="true" />
            </a>
          </div>
        </div>
      </div>

      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel
          focus
          className="fixed top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden z-20"
        >
          <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
            <div className="pt-5 pb-6 px-5">
              <div className="flex items-center justify-between">
                <div>
                  <img
                    className="h-8 w-auto"
                    src="https://avatars.githubusercontent.com/u/51781549?v=4"
                    alt="Workflow"
                  />
                </div>
                <div className="-mr-2">
                  <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none">
                    <span className="sr-only">Close menu</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="mt-6">
                <nav className="grid gap-y-8">
                  {links.map((item) => (
                    <a
                      key={item.name}
                      href={`${item.href}${item.hash}`}
                      className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50"
                    >
                      <item.icon
                        className="flex-shrink-0 h-6 w-6 text-yellow-300"
                        aria-hidden="true"
                      />
                      <span className="ml-3 text-base font-medium text-gray-900">
                        {item.name}
                      </span>
                    </a>
                  ))}
                </nav>
              </div>
            </div>
            <div className="py-6 px-5 space-y-6">
              <div>
                <a
                  href="mailto:anfrage@omegalul.co"
                  className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-black bg-yellow-300 hover:bg-yellow-400"
                >
                  <MailIcon className="h-6 w-6" aria-hidden="true" />
                </a>
              </div>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}
