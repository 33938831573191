import React from "react";
import NavBar from "../Components/NavBar";
import ItemPage from "../Components/ItemPage";
import ReactPlayer from "react-player";
import automated_video1 from "../assets/automated_video.mp4";
import automated_video2 from "../assets/automated_video2.mp4";
import automated_video3 from "../assets/automated_video3.mp4";

export default function AutomatedMedia() {
  return (
    <div>
      <NavBar />
      <div className="flex items-center flex-col my-36">
        <div className="w-11/12 md:w-8/12 p-4 shadow-2xl bg-white">
          <div className="flex flex-col gap-20 bg-white p-4 md:p-8 w-full border-yellow-700 border-t-2 border-l-2 pt-8 pl-8">
            {/* Header (Title) */}
            <div>
              <p className="text-6xl font-bold drop-shadow-lg underline decoration-2 underline-offset-2 decoration-yellow-700">
                Autonome Medien Generierung
              </p>
            </div>
            {/* Content */}
            <div className="flex flex-col gap-16">
              {/* Anforderung */}
              <div>
                <p className="text-4xl">Anforderung:</p>
                <p>
                  Es sollte ein Programm geschaffen werden, das durch reines
                  aufrufen automatisch ein Video von knapp 60 Sekunden
                  generiert. Die Daten für das Video sollten aus einer API
                  kommen, und somit dynamisch sein.
                </p>
              </div>
              {/* Umsetzung */}
              <div>
                <p className="text-4xl">Umsetzung:</p>
                <p>
                  Umgesetzt wurde das ganze mit Remotion und React. Remotion ist
                  eine library, die das erstellen und rendern von mp4 Dateien
                  mit React ermöglicht. Die Daten für die Videos kommen hierfür
                  von Reddit, einer Social-Media-Plattform auf welcher User
                  Diskussionen führen können, was damit die ideale Datenquelle
                  für dieses Projekt ist. Als Vorleser wurde sich für eine TTS
                  (Text-To-Speech) Lösung entschieden, in diesem Fall Cloud
                  Text-to-Speech von Google Cloud. Hierfür wurde eine Serverless
                  Funktion erstellt, welche durch Aufruf mit einer Text query
                  eine mp3 Datei erstellt, welche dann hinter das Video gelegt
                  wird.
                </p>
              </div>
              {/* Endergebnis */}
              <div>
                <p className="text-4xl mb-2">Ergebnis:</p>
                <div className="w-full grid grid-cols-3 gap-4">
                  <ReactPlayer
                    url={automated_video1}
                    height="100%"
                    width="100%"
                    controls
                    loop
                  />
                  <ReactPlayer
                    url={automated_video2}
                    height="100%"
                    width="100%"
                    controls
                    loop
                  />
                  <ReactPlayer
                    url={automated_video3}
                    height="100%"
                    width="100%"
                    controls
                    loop
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
