import React, { useState, useEffect, Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import {
  BookmarkAltIcon,
  CalendarIcon,
  ChartBarIcon,
  CursorClickIcon,
  MenuIcon,
  PhoneIcon,
  PlayIcon,
  RefreshIcon,
  ShieldCheckIcon,
  SupportIcon,
  ViewGridIcon,
  XIcon,
  UserIcon,
  MailIcon,
} from "@heroicons/react/outline";
import { ChevronDownIcon } from "@heroicons/react/solid";

import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import { FaDiscord, FaHandPeace, FaHandPointDown } from "react-icons/fa";
import { BsMouse } from "react-icons/bs";
import Typical from "../Components/Typical";
import NavBar from "../Components/NavBar";
import Button from "../Components/Button";
import Tooltip from "../Components/Tooltip";

// all Items in KnownTech in an array
const KnownTechArray = [
  {
    title: "JavaScript",
    img: "https://upload.wikimedia.org/wikipedia/commons/thumb/9/99/Unofficial_JavaScript_logo_2.svg/1200px-Unofficial_JavaScript_logo_2.svg.png",
    link: "https://developer.mozilla.org/en-US/docs/Web/JavaScript",
  },
  {
    title: "TypeScript",
    img: "https://upload.wikimedia.org/wikipedia/commons/thumb/4/4c/Typescript_logo_2020.svg/500px-Typescript_logo_2020.svg.png",
    link: "https://www.typescriptlang.org/",
  },
  {
    title: "VS Code",
    img: "https://upload.wikimedia.org/wikipedia/commons/thumb/9/9a/Visual_Studio_Code_1.35_icon.svg/2048px-Visual_Studio_Code_1.35_icon.svg.png",
    link: "https://code.visualstudio.com/",
  },
  {
    title: "React",
    img: "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a7/React-icon.svg/1200px-React-icon.svg.png",
    link: "https://reactjs.org/",
  },
  {
    title: "Next.js",
    img: "https://upload.wikimedia.org/wikipedia/commons/thumb/8/8e/Nextjs-logo.svg/330px-Nextjs-logo.svg.png",
    link: "https://nextjs.org/",
  },
  {
    title: "Node.js",
    img: "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d9/Node.js_logo.svg/1200px-Node.js_logo.svg.png",
    link: "https://nodejs.org/en/",
  },
  {
    title: "Tailwind CSS",
    img: "https://upload.wikimedia.org/wikipedia/commons/d/d5/Tailwind_CSS_Logo.svg",
    link: "https://tailwindcss.com/",
  },
  {
    title: "Express.js",
    img: "https://expressjs.com/images/express-facebook-share.png",
    link: "https://expressjs.com/",
  },
  {
    title: "Axios",
    img: "https://avatars.githubusercontent.com/u/32372333?s=200&v=4",
    link: "https://axios-http.com/",
  },
  {
    title: "MySQL",
    img: "https://upload.wikimedia.org/wikipedia/de/thumb/d/dd/MySQL_logo.svg/1200px-MySQL_logo.svg.png",
    link: "https://www.mysql.com/",
  },
  {
    title: "Firebase",
    img: "https://www.gstatic.com/devrel-devsite/prod/v84e6f6a61298bbae5bb110c196e834c7f21fe3fb34e722925433ddb936d280c9/firebase/images/touchicon-180.png",
    link: "https://firebase.google.com/",
  },
  {
    title: "Google Cloud",
    img: "https://www.gstatic.com/devrel-devsite/prod/v777b98ff1be68f1af7b3b25f83928f30c18a7dbd2a5bebb30a0e65cbbadfd895/cloud/images/favicons/onecloud/apple-icon.png",
    link: "https://cloud.google.com/",
  },
  {
    title: "Remotion",
    img: "https://www.remotion.dev/img/logo-small.png",
    link: "https://www.remotion.dev/",
  },
  {
    title: "Git",
    img: "https://www.netways.de/wp-content/uploads/2014/02/Git-Icon-1788C.png",
    link: "https://git-scm.com/",
  },
  {
    title: "Github",
    img: "https://pbs.twimg.com/profile_images/1414990564408262661/r6YemvF9_400x400.jpg",
    link: "https://github.com/",
  },
  {
    title: "Cloudflare",
    img: "https://pbs.twimg.com/profile_images/1313131647315910666/opulcRqc_400x400.jpg",
    link: "https://www.cloudflare.com/",
  },
  {
    title: "MUI (Material UI)",
    img: "https://mui.com/static/logo.png",
    link: "https://mui.com/",
  },
  {
    title: "GitHub Copilot",
    img: "https://github.githubassets.com/images/icons/copilot/cp-head-square.png",
    link: "https://copilot.github.com/",
  },
  {
    title: "Postman",
    img: "https://stg-tud.github.io/sep/projects/2018/TeamMicro/site/img/postman.png",
    link: "https://postman.com/",
  },
];

const MyProjectsArray = [
  {
    title: "Sharrings\r",
    description:
      "Eine Plattform, auf welcher man all seine Accounts und Benutzernamen verlinken kann. Entwickelt mit React, Tailwindcss, Firebase und Google Cloud Platform\r",
    img: "sharrings.png\r",
    link: "https://sharrin.gs/\r",
  },
  {
    title: "Autonome Medien Generierung",
    description:
      "Automatische generierung von Videos basierend auf Dynamischem Content. Entwickelt mit Remotion, React, Tailwindcss und Google Cloud Platform.",
    img: "automated_media.png",
    link: "/projects/automated-media",
    buttontext: "Mehr Erfahren!",
    targetbool: true,
  },
  {
    title: "Pegel Projekt\r",
    description:
      "Monitoring Dashboard zur Überwachung verschiedener Prozesse und Server. Bestehend aus Datenbank, API, Automation und Web Dashboard.\r",
    img: "pegel_1.png\r",
    link: "/projects/pegel",
    buttontext: "Mehr Erfahren!\r",
    targetbool: true,
  },
  {
    title: "ModBox\r",
    description:
      "Eine Twitch Moderatoren Analytics Seite mit der man die aktivität seiner Moderatoren tracken kann und die Rollen auf dem Kanal verwalten kann (Mod und VIP). Entwickelt mit React, MUI, Minimal UI, Firebase und Google Cloud Platform.\r",
    img: "modbox.png\r",
    link: "https://modbox.one/\r",
  },
  {
    title: "Omegalul.co\r",
    description:
      "Eine Spaß Website mit einem Mini Spiel und allen Links zu Papaplatte, mit komplett eigenem Design. Entwickelt mit React, MUI und Tailwindcss.\r",
    img: "omegalulco.png\r",
    link: "https://omegalul.co/\r",
  },
  {
    title: "Flotten Manager Konzept\r",
    description:
      "Funktionsfähiges Flotten Manager Konzept. Entwickelt mit React und MUI.\r",
    img: "fleetmanager.png\r",
  },
  {
    title: "Simple Twitch API\r",
    description:
      "Eine einfachere Implementierung, öffentlicher Features der Twitch API. Entwickelt mit NodeJS, Express.js und Axios.\r",
    img: "twitchapi.png\r",
    link: "https://rapidapi.com/team-amg-team-amg-default/api/twitch-advanced/\r",
  },
  {
    title: "QR Code API\r",
    description:
      "Eine API die custom QR Codes Generiert. Entwickelt mit NodeJS und Express.js.\r",
    img: "qrcode.png\r",
    link: "https://rapidapi.com/team-amg-team-amg-default/api/qrcode-generator1//\r",
  },
  {
    title: "Landing Picker\r",
    description:
      "Ein Windows Programm was einen Random Landing Spot für Apex, Fortnite oder Warezone auswählt. Entwickelt mit C++ und Qt.\r",
    img: "randomlanding.png\r",
  },
  {
    title: "CAN BUS Reader\r",
    description:
      "Ein Programm welches per CAN BUS (Wird z.B. in Autos genutzt) Motordaten ausließt und anzeigt. Entwickelt mit C++ und Qt.\r",
    img: "canbus.png\r",
  },
];

function Landing() {
  return (
    <div className="">
      <NavBar translatey />
      <div className="flex items-center flex-col space-y-24 md:space-y-60 gap-0">
        <div className="w-10/12 md:w-8/12">
          <Header2 />
        </div>
        <div className="scroll-m-20 flex flex-col gap-4" id="tech">
          <p className="text-center text-6xl font-semibold">Das nutze ich</p>
          <KnownTech2 />
        </div>
        <div className="2xl:w-3/6 lg:w-4/6 sm:w-5/6 scroll-m-20" id="about">
          <HeroText2 />
        </div>
        <div className="lg:w-4/6 w-full scroll-m-20" id="projects">
          <MyProjects2 />
        </div>
      </div>
      <ScrollIcon />
      {/* <ParalexItems /> */}
      <div className="text-center m-4 mt-12 text-black">
        <p>
          Made with much ❤️ by{" "}
          <a href="https://derzerstampfer.omegalul.co/" target="_blank">
            Pau Kraft
          </a>
        </p>
      </div>
    </div>
  );
}

function Header() {
  return (
    <>
      <div className="flex justify-evenly rounded-xl p-4 bg-gray-50 mt-20 shadow-lg">
        <div className="flex flex-col items-center">
          <div className="relative w-32 md:w-52 circle z-0 shadow-md rounded-full">
            <div className="border"></div>
            <img src="https://avatars.githubusercontent.com/u/51781549?v=4" />
          </div>

          <p className="text-2xl md:text-5xl text-center font-bold cursor-default">
            Pau Kraft
          </p>
          {/* <p className="text-center text-gray-600 text-sm cursor-default mb-2">Full Stack Web Developer / Software Developer</p> */}
          <Typical
            steps={[
              "Full Stack Web Developer",
              2000,
              "Software Developer",
              1000,
              "Gamer",
              1000,
            ]}
            loop={Infinity}
            wrapper="p"
            className="text-center text-xs md:text-sm cursor-default mb-2 text-yellow-700"
          />
          <Divider className="w-4/5" />
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            className="mt-2"
          >
            <FaDiscord className="mr-2 text-black hover:text-blue-800" />
            <p className="text-gray-800 hover:text-black select-all">
              DerZerstampfer#7112
            </p>
          </Grid>
        </div>
      </div>
    </>
  );
}

function Header2() {
  return (
    <div className="mt-24ä h-screen flex flex-row justify-around items-center">
      {/* Peace Image */}
      <div className="w-1/4">
        <HeaderImage />
      </div>
      {/* Text */}
      <div>
        <p className="text-4xl sm:text-6xl md:text-7xl lg:text-8xl text-center font-bold cursor-default">
          Pau Kraft
        </p>
        <Typical
          steps={[
            "Software Developer",
            2000,
            "Back End Web Developer",
            2000,
            "Front End Web Developer",
            2000,
          ]}
          loop={Infinity}
          wrapper="p"
          className="text-center text-xs min-h-[1rem] sm:text-base sm:min-h-[1.5rem] md:text-lg md:min-h-[1.75rem] lg:text-2xl lg:min-h-[2rem] cursor-default mb-2 text-yellow-700"
        />
      </div>
    </div>
  );
}

function HeaderImage() {
  const [offsetY, setOffsetY] = useState(0);
  const handleScroll = () => setOffsetY(window.pageYOffset);
  const [loading, setLoading] = useState(true);
  const [hidden, setHidden] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  return (
    <>
      <img
        src="peace.webp"
        style={{
          transform: `rotate(${offsetY / 10}deg)`,
        }}
        onLoad={() => setLoading(false)}
      />
      <div
        className={`w-full h-screen fixed top-0 left-0 bg-white z-50 ${
          !loading && "opacity-0"
        } ${hidden && "hidden"} duration-200 ease-out`}
        onTransitionEnd={() => setHidden(true)}
      />
    </>
  );
}

function SideBySideheader() {
  return (
    <div>
      <Grid
        container
        direction="row"
        justifyContent="space-evenly"
        alignItems="center"
      >
        <Grid item className="w-1/4 pl-14">
          <p className="text-4xl text-white font-semibold text-left">
            Hey There,
            <br />
            Ich bin Pau.
          </p>
          <Typical
            steps={[
              "Full Stack Web Developer",
              2000,
              "Software Developer",
              1000,
              "Java Entwickler",
              1000,
            ]}
            loop={Infinity}
            wrapper="span"
            className="text-red-800 text-2xl"
          />
        </Grid>
        <Grid item className="w-1/4">
          <div className="circle">
            <div className="border"></div>
            <img src="https://avatars.githubusercontent.com/u/51781549?v=4" />
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

function HeroText() {
  return (
    <div className="text-black cursor-default bg-white shadow-md" id="herotext">
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        className="bg-yellow-300"
      >
        <p className="text-5xl mr-2 font-bold py-2">Über mich</p>
        {/* <FaHandPeace className="text-orange-400 text-4xl" /> */}
      </Grid>
      <Typical
        steps={[
          `Ich heiße Pau bin ${getAge()} Jahre alt und bin leidenschaftlicher Software Entwickler.`,
          1000,
          `Ich heiße Pau bin ${getAge()} Jahre alt und bin leidenschaftlicher Software Entwickler. Am meisten bin ich in JavaScript unterwegs, sei's im Frontend mit React oder im Backend mit NodeJs.`,
          1000,
          `Ich heiße Pau bin ${getAge()} Jahre alt und bin leidenschaftlicher Software Entwickler. Am meisten bin ich in JavaScript unterwegs, sei's im Frontend mit React oder im Backend mit NodeJs. Von Datenbanken zu API's bis hin zur funktionalen Website bin ich mit allem vertraut.`,
          1000,
        ]}
        loop={1}
        wrapper="p"
        className="text-black mt-2 text-lg text-center p-2"
      />
    </div>
  );
}

function HeroText2() {
  return (
    <div className="group cursor-default">
      <div className="absolute translate-y-10 md:translate-x-0 h-36 w-36 scale-[200%] rotate-6 group-hover:rotate-12 duration-1000 animate-pulse -z-10">
        <img src="gitter.svg" className="absolute opacity-5" alt="gitter" />
      </div>
      <div className="z-10 group-hover:drop-shadow-lg duration-500">
        <div>
          <span className="text-6xl font-semibold text-left mb-8">
            Über mich...
          </span>
        </div>
        <Divider className="absolute w-screen" />
        <div>
          <p className="text-black mt-2 text-lg text-left p-2">
            Ich bin Softwareentwickler, spezialisiert auf Full Stack Web
            Anwendungen. Mein Können fokussiert sich auf Frontend Entwicklung
            mit{" "}
            <Tooltip
              tooltip="React ist eine JavaScript-Softwarebibliothek, die ein Grundgerüst für die Ausgabe von User-Interface-Komponenten von Webseiten zur Verfügung stellt."
              text
            >
              React
            </Tooltip>{" "}
            und Backend mit{" "}
            <Tooltip
              tooltip="Node.js ist eine plattformübergreifende Open-Source-JavaScript-Laufzeitumgebung, die JavaScript-Code außerhalb eines Webbrowsers ausführen kann. Damit kann zum Beispiel ein Webserver betrieben werden."
              text
            >
              Node.JS
            </Tooltip>
            . Ich setze viel Wert auf clean Code, sicheren Code und Menschen
            fokussiertes Design.
          </p>
          {/* <Typical
          steps={[
            `Ich heiße Pau bin ${getAge()} Jahre alt und bin leidenschaftlicher Software Entwickler.`,
            1000,
            `Ich heiße Pau bin ${getAge()} Jahre alt und bin leidenschaftlicher Software Entwickler. Am meisten bin ich in JavaScript unterwegs, sei's im Frontend mit React oder im Backend mit NodeJs.`,
            1000,
            `Ich heiße Pau bin ${getAge()} Jahre alt und bin leidenschaftlicher Software Entwickler. Am meisten bin ich in JavaScript unterwegs, sei's im Frontend mit React oder im Backend mit NodeJs. Von Datenbanken zu API's bis hin zur funktionalen Website bin ich mit allem vertraut.`,
            1000,
          ]}
          loop={1}
          wrapper="p"
          className="text-black mt-2 text-lg text-left p-2"
        /> */}
        </div>
      </div>
    </div>
  );
}

function KnownTech() {
  return (
    <div className="text-black">
      <p className="text-6xl font-extrabold text-center mb-8">
        Technologien die ich nutze!
      </p>
      <div className="flex flex-row flex-wrap justify-center gap-4">
        <TechItem
          title="JavaScript"
          img="https://upload.wikimedia.org/wikipedia/commons/thumb/9/99/Unofficial_JavaScript_logo_2.svg/1200px-Unofficial_JavaScript_logo_2.svg.png"
        />
        <TechItem
          title="VS Code"
          img="https://upload.wikimedia.org/wikipedia/commons/thumb/9/9a/Visual_Studio_Code_1.35_icon.svg/2048px-Visual_Studio_Code_1.35_icon.svg.png"
        />
        <TechItem
          title="React"
          img="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a7/React-icon.svg/1200px-React-icon.svg.png"
        />
        <TechItem
          title="NodeJS"
          img="https://upload.wikimedia.org/wikipedia/commons/thumb/d/d9/Node.js_logo.svg/1200px-Node.js_logo.svg.png"
        />
        <TechItem
          title="Tailwind CSS"
          img="https://upload.wikimedia.org/wikipedia/commons/d/d5/Tailwind_CSS_Logo.svg"
        />
        <TechItem
          title="Express.js"
          img="https://expressjs.com/images/express-facebook-share.png"
        />
        <TechItem
          title="Axios"
          img="https://avatars.githubusercontent.com/u/32372333?s=200&v=4"
        />
        <TechItem
          title="MySQL"
          img="https://upload.wikimedia.org/wikipedia/de/thumb/d/dd/MySQL_logo.svg/1200px-MySQL_logo.svg.png"
        />
        <TechItem
          title="Firebase"
          img="https://www.gstatic.com/devrel-devsite/prod/v84e6f6a61298bbae5bb110c196e834c7f21fe3fb34e722925433ddb936d280c9/firebase/images/touchicon-180.png"
        />
        <TechItem
          title="Google Cloud"
          img="https://www.gstatic.com/devrel-devsite/prod/v777b98ff1be68f1af7b3b25f83928f30c18a7dbd2a5bebb30a0e65cbbadfd895/cloud/images/favicons/onecloud/apple-icon.png"
        />
        <TechItem
          title="Git"
          img="https://www.netways.de/wp-content/uploads/2014/02/Git-Icon-1788C.png"
        />
        <TechItem
          title="Github"
          img="https://pbs.twimg.com/profile_images/1414990564408262661/r6YemvF9_400x400.jpg"
        />
        <TechItem
          title="Cloudflare"
          img="https://pbs.twimg.com/profile_images/1313131647315910666/opulcRqc_400x400.jpg"
        />
        <TechItem
          title="MUI (Material UI)"
          img="https://mui.com/static/logo.png"
        />
        <TechItem
          title="GitHub Copilot"
          img="https://github.githubassets.com/images/icons/copilot/cp-head-square.png"
        />
        <TechItem
          title="Postman"
          img="https://stg-tud.github.io/sep/projects/2018/TeamMicro/site/img/postman.png"
        />
      </div>
    </div>
  );
}

function TechItem(props) {
  return (
    <div className="bg-white shadow-md hover:shadow-lg hover:scale-125 duration-100 flex flex-col">
      <div>
        <p className="text-center text-black bg-yellow-300">{props.title}</p>
      </div>
      <div className="h-full flex justify-center">
        <img
          src={props.img}
          alt={props.title}
          className="w-32 object-contain"
        />
      </div>
    </div>
  );
}

function KnownTech2() {
  return (
    <div className="w-screen" style={{ overflow: "hidden" }}>
      <div className="text-black flex gap-4 absolute left-0 animate-[slide_30s_linear_infinite]">
        {KnownTechArray.map((item, index) => (
          <TechItem2
            title={item.title}
            img={item.img}
            link={item.link}
            key={index}
          />
        ))}
        {KnownTechArray.map((item, index) => (
          <TechItem2
            title={item.title}
            img={item.img}
            link={item.link}
            key={index}
          />
        ))}
      </div>
      <div className="h-24 md:h-32"></div>
    </div>
  );
}

function TechItem2(props) {
  return (
    <div
      className="flex flex-col items-center justify-center ring-1 ring-yellow-700 m-1 drop-shadow-md bg-white hover:scale-105 duration-200 group cursor-pointer"
      onClick={() => window.open(props.link, "_blank").focus()}
    >
      <Tooltip tooltip={props.title} nowrap>
        <div className="h-24 w-24 md:h-32 md:w-32 flex items-center">
          <img src={props.img} className="object-contain select-none" />
        </div>
      </Tooltip>
    </div>
  );
}

function MyProjects() {
  return (
    <div className="text-black">
      <p className="text-6xl font-semibold text-center mb-8">Meine Projekte</p>
      <Divider />
      <div className="flex flex-col gap-1 snap-proximity snap-y">
        <ProjectItem
          title="Sharrings"
          description="Eine Plattform, auf welcher man all seine Accounts und Benutzernamen verlinken kann. Entwickelt mit React, Tailwindcss, Firebase, Google Cloud Platform"
          img="sharrings.png"
          link="https://sharrin.gs/"
        />
        <Divider />
        <ProjectItem
          title="Autonome Medien erstellung"
          description="Automatische generierung von Videos basierend auf Dynamischem Content. Entwickelt mit Remotion, React, Tailwindcss und Google Cloud Platform."
          img="automated_media.png"
          link="/projects/autonome-medien-erstellung"
          targetbool
          left
          buttontext="Mehr erfahren"
        />
        <Divider />
        <ProjectItem
          title="Pegel Projekt"
          description="Monitoring Dashboard zur Überwachung verschiedener Prozesse und Server. Bestehend aus Datenbank, API, Automation und Web Dashboard."
          img="pegel_1.png"
          link="/projects/pegel"
          targetbool
          buttontext="Mehr Erfahren!"
        />
        <Divider />
        <ProjectItem
          title="ModBox"
          description="Eine Twitch Moderatoren Analytics Seite mit der man die aktivität seiner Moderatoren tracken kann und die Rollen auf dem Kanal verwalten kann (Mod und VIP). Entwickelt mit React, MUI, Minimal UI, Firebase und Google Cloud Platform."
          img="modbox.png"
          link="https://modbox.one/"
          left
        />
        <Divider />
        <ProjectItem
          title="Omegalul.co"
          description="Eine Spaß Website mit einem Mini Spiel und allen Links zu Papaplatte, mit komplett eigenem Design. Entwickelt mit React, MUI und Tailwindcss."
          img="omegalulco.png"
          link="https://omegalul.co/"
        />
        <Divider />
        <ProjectItem
          title="Flotten Manager Konzept"
          description="Funktionsfähiges Flotten Manager Konzept. Entwickelt mit React und MUI."
          img="fleetmanager.png"
          left
        />
        <Divider />
        <ProjectItem
          title="Simple Twitch API"
          description="Eine einfachere Implementierung, öffentlicher Features der Twitch API. Entwickelt mit NodeJS, Express.js und Axios."
          img="twitchapi.png"
          link="https://rapidapi.com/team-amg-team-amg-default/api/twitch-advanced/"
        />
        <Divider />
        <ProjectItem
          title="QR Code API"
          description="Eine API die custom QR Codes Generiert. Entwickelt mit NodeJS und Express.js."
          img="qrcode.png"
          link="https://rapidapi.com/team-amg-team-amg-default/api/qrcode-generator1//"
          left
        />
        <Divider />
        <ProjectItem
          title="Landing Picker"
          description="Ein Windows Programm was einen Random Landing Spot für Apex, Fortnite oder Warezone auswählt. Entwickelt mit C++ und Qt."
          img="randomlanding.png"
        />
        <Divider />
        <ProjectItem
          title="CAN BUS Reader"
          description="Ein Programm welches per CAN BUS (Wird z.B. in Autos genutzt) Motordaten ausließt und anzeigt. Entwickelt mit C++ und Qt."
          img="canbus.png"
          left
        />
      </div>
    </div>
  );
}

function ProjectItem(props) {
  const { title, img, description, link, left, targetbool, buttontext } = props;
  return (
    <div className="hover:bg-blue-50 hover:shadow-md p-4 rounded-lg md:hover:scale-110 duration-300 snap-center">
      <a
        href={link}
        target={!targetbool ? "_blank" : "_self"}
        rel="noopener noreferrer"
      >
        {/* <Grid container direction="row" justifyContent="center" alignItems="center"> */}
        <div className="flex justify-center items-center space-x-4">
          {left ? (
            <div className="flex flex-col md:flex-row-reverse md:gap-14 items-center">
              <div className="md:w-1/2 hidden md:flex md:justify-start">
                <img
                  src={img}
                  alt={title}
                  className="h-max max-h-[325px] w-auto"
                />
              </div>
              <div className="md:w-1/2">
                <div className="text-center md:text-right">
                  <p className="text-center md:text-right text-3xl text-yellow-700">
                    {title}
                  </p>
                  <p>{description}</p>
                  <div className="flex justify-center w-full p-4 md:p-8">
                    <Button className={`${!buttontext && "hidden"} w-max`}>
                      {buttontext}
                    </Button>
                  </div>
                </div>
              </div>
              <div className="md:w-1/2 md:hidden">
                <img src={img} alt={title} className="w-max h-auto" />
              </div>
            </div>
          ) : (
            <div className="flex flex-col md:flex-row md:gap-14 items-center">
              <div className="md:w-1/2 hidden md:flex md:justify-end">
                <img
                  src={img}
                  alt={title}
                  className="h-max max-h-[325px] w-auto"
                />
              </div>
              <div className="md:w-1/2">
                <div className="text-center md:text-left">
                  <p className="text-center md:text-left text-3xl text-yellow-700">
                    {title}
                  </p>
                  <p>{description}</p>
                  <div className="flex justify-center w-full p-4 md:p-8">
                    <Button className={`${!buttontext && "hidden"} w-max`}>
                      {buttontext}
                    </Button>
                  </div>
                </div>
              </div>
              <div className="md:w-1/2 md:hidden">
                <img src={img} alt={title} className="w-max h-auto" />
              </div>
            </div>
          )}
        </div>
      </a>
    </div>
  );
}

function MyProjects2() {
  return (
    <div>
      <p className="text-6xl font-semibold text-center mb-8">Meine Projekte</p>
      <div className="flex flex-col gap-8">
        {MyProjectsArray.map((item, index) => (
          <>
            <ProjectItem2
              title={item.title}
              description={item.description}
              img={item.img}
              link={item.link}
              buttontext={item.buttontext}
              targetbool={item.targetbool}
              index={index}
            />
          </>
        ))}
      </div>
    </div>
  );
}

function ProjectItem2(props) {
  const { title, img, description, link, targetbool, buttontext, index } =
    props;
  return (
    <a
      className={`flex ${
        index % 2 ? "flex-row-reverse" : "flex-row"
      } justify-between w-full p-4 shadow-xl hover:scale-105 hover:shadow-2xl hover:shadow-yellow-700 duration-200`}
      href={link}
      target={!targetbool ? "_blank" : "_self"}
      rel="noopener noreferrer"
    >
      {/* Text */}
      <div
        className={`w-1/3 text-center md:text-left flex items-center border-yellow-700 ${
          index % 2
            ? "border-b-2 border-r-2 pb-8 pr-8"
            : "border-b-2 border-l-2 pb-8 pl-8"
        }`}
      >
        <div className="flex flex-col gap-3">
          <p className="text-center md:text-left font-semibold text-3xl text-yellow-700">
            {title}
          </p>
          <p>{description}</p>
          <div>
            <Button className={`${!buttontext && "hidden"}`}>
              {buttontext}
            </Button>
          </div>
        </div>
      </div>
      {/* Image */}
      <div
        className={`w-1/2 h-auto border-yellow-700 ${
          index % 2
            ? "border-t-2 border-l-2 pt-8 pl-8"
            : "border-t-2 border-r-2 pt-8 pr-8"
        }`}
      >
        <img
          src={img}
          alt={title}
          className={`w-auto h-full max-h-[217px] drop-shadow-sm`}
        />
      </div>
    </a>
  );
}

function ScrollIcon() {
  const [showScroll, setShowScroll] = useState(true);

  const checkScrollTop = () => {
    if (showScroll && window.pageYOffset > 60) {
      setShowScroll(false);
    } else if (!showScroll && window.pageYOffset <= 60) {
      setShowScroll(true);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", checkScrollTop);
    return () => {
      window.removeEventListener("scroll", checkScrollTop);
    };
  }, [showScroll]);

  return (
    <div>
      <div className="hidden md:flex">
        <BsMouse
          className="fixed bottom-5 items-center justify-center left-1/2 text-white w-12 md:w-16 h-auto breath bg-black bg-opacity-80 rounded-full p-2 -ml-6 md:-ml-8"
          style={{ display: showScroll ? "flex" : "none" }}
        />
      </div>
      <div className="md:hidden">
        <FaHandPointDown
          className="fixed bottom-5 items-center justify-center left-1/2 text-white w-12 md:w-16 h-auto breath bg-black bg-opacity-80 rounded-full p-2 -ml-6 md:-ml-8"
          style={{ display: showScroll ? "flex" : "none" }}
        />
      </div>
    </div>
  );
}

function ParalexItems() {
  const [offsetY, setOffsetY] = useState(0);
  const handleScroll = () => setOffsetY(window.pageYOffset);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className="">
      <div>
        <div
          className="bg-yellow-300 w-52 h-32 absolute top-16 -left-40 rounded-full -z-10 filter blur-xl mix-blend-multiply opacity-40"
          style={{
            transform: `translateY(${offsetY * 1.4}px) translateX(${
              offsetY * 1.5
            }px)`,
          }}
        />
        <div
          className="bg-pink-400 w-32 h-36 absolute top-28 -left-28 rounded-full -z-10 filter blur-xl mix-blend-multiply opacity-40"
          style={{
            transform: `translateY(${offsetY * 1.4}px) translateX(${
              offsetY * 1.5
            }px)`,
          }}
        />
        <div
          className="bg-cyan-500 w-32 h-36 absolute top-32 -left-40 rounded-full -z-10 filter blur-xl mix-blend-multiply opacity-40"
          style={{
            transform: `translateY(${offsetY * 1.4}px) translateX(${
              offsetY * 1.5
            }px)`,
          }}
        />
      </div>
      <div>
        <div
          className="bg-yellow-300 w-52 h-32 absolute top-[400px] -left-[500px] rounded-full -z-10 filter blur-xl mix-blend-multiply opacity-40"
          style={{
            transform: `translateY(${offsetY * 1.2}px) translateX(${
              offsetY * 1.3
            }px)`,
          }}
        />
        <div
          className="bg-pink-400 w-32 h-36 absolute top-[420px] -left-[520px] rounded-full -z-10 filter blur-xl mix-blend-multiply opacity-40"
          style={{
            transform: `translateY(${offsetY * 1.2}px) translateX(${
              offsetY * 1.35
            }px)`,
          }}
        />
        <div
          className="bg-cyan-500 w-32 h-36 absolute top-[440px] -left-[540px] rounded-full -z-10 filter blur-xl mix-blend-multiply opacity-40"
          style={{
            transform: `translateY(${offsetY * 1.2}px) translateX(${
              offsetY * 1.3
            }px)`,
          }}
        />
      </div>
    </div>
  );
}

function getAge() {
  var today = new Date();
  var birthDate = new Date("2002/09");
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}

export default Landing;
