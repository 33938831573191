import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

import Landing from "./Pages/Landing";
import PegelPage from "./Pages/PegelPage";
import AutomatedMedia from "./Pages/AutomatedMedia";
import NotFound from "./Pages/NotFound";

function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/">
          <Route path="*" element={<Navigate replace to="/404" />} />
          <Route index element={<Landing />} />
          <Route path="projects">
            <Route path="pegel" element={<PegelPage />} />
            <Route path="automated-media" element={<AutomatedMedia />} />
          </Route>
          <Route path="404" element={<NotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
