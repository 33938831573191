import React from "react";
import NavBar from "./NavBar";

export default function ItemPage(props) {
    const { children, title, propLinks } = props;
    return (
        <div>
            <NavBar propLinks={propLinks} />
            <div className="flex items-center flex-col my-36">
                <div className="flex flex-col gap-12 items-center justify-center rounded-2xl drop-shadow-lg bg-white p-4 md:p-8 border-2 border-gray-100 w-11/12 md:w-8/12">
                    <span className="absolute -top-5 md:-top-7 left-6 md:left-8 text-3xl md:text-5xl font-bold drop-shadow-md">
                        {title}
                    </span>
                    {children}
                </div>
            </div>
        </div>
    );
}
